import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`High contrast between text and its background benefits everyone. Be it people with low vision or anyone looking
at the screen in the glaring sun. That's why `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
      }}>{`WCAG`}</a>{`
has minimum requirements for contrast of text and UI components.`}</p>
    <p>{`For some visually impaired users, this is not enough. They need specific color themes with high contrast to increase
text legibility and improve readability. Assistive technology like
`}<a parentName="p" {...{
        "href": "https://assistivlabs.com/assistive-tech/display/high-contrast-mode"
      }}>{`High Contrast Mode`}</a>{` on Windows can actively
change a website's colors to accomodate users with limited vision.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAQBA//aAAwDAQACEAMQAAABmNqV5nqLBVf/xAAbEAEAAgIDAAAAAAAAAAAAAAABAAIDESEiM//aAAgBAQABBQIlOospy4vNdP8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAIRAf/aAAgBAwEBPwFFulP/xAAZEQACAwEAAAAAAAAAAAAAAAAAAwEEESH/2gAIAQIBAT8BsNzSF8P/xAAYEAACAwAAAAAAAAAAAAAAAAABEQACIP/aAAgBAQAGPwKGyYz/AP/EABoQAQACAwEAAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8h2jFKm/PYLSAlYhw9gNyxmADByf/aAAwDAQACAAMAAAAQ7M//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxCClnRzkP/EABgRAQADAQAAAAAAAAAAAAAAAAEAEUGx/9oACAECAQE/EAKrTsvDP//EABsQAQEAAwADAAAAAAAAAAAAAAERACFRMUHB/9oACAEBAAE/EEpObuLoAMedsNJIKvrIVS0vIL8xuqGI8y4U0BoBn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A mix of different colors on a canvas.",
          "title": "A mix of different colors on a canvas.",
          "src": "/static/136267c5e3abf2262b79bb7ff5634d39/e5166/pexels-steve-johnson-color-palette.jpg",
          "srcSet": ["/static/136267c5e3abf2262b79bb7ff5634d39/f93b5/pexels-steve-johnson-color-palette.jpg 300w", "/static/136267c5e3abf2262b79bb7ff5634d39/b4294/pexels-steve-johnson-color-palette.jpg 600w", "/static/136267c5e3abf2262b79bb7ff5634d39/e5166/pexels-steve-johnson-color-palette.jpg 1200w", "/static/136267c5e3abf2262b79bb7ff5634d39/b17f8/pexels-steve-johnson-color-palette.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Steve Johnson / pexels.com`}</em></p>
    <p>{`I'll show you how forced colors mode works for websites and how to improve the user experience with some CSS tweaks.`}</p>
    <h2>{`How does Forced Colors Mode work?`}</h2>
    <p>{`Forced colors mode is a cross-browser standard defined in
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-color-adjust-1/#forced"
      }}>{`CSS Color Adjustment Module Level 1`}</a>{`. Operating systems
should provide built-in color themes and allow users to customize their own themes.`}</p>
    <p>{`The specification states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`When `}<strong>{`forced colors mode`}</strong>{` is active (...), colors on the element are force-adjusted to the user’s preferred color palette.`}</p>
    </blockquote>
    <p>{`This means that color values are replaced with `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-color-4/#css-system-colors"
      }}>{`system colors`}</a>{` to match the
defined theme. Among the affected CSS properties are:`}</p>
    <ul>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">color</InlineCode>
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">background-color</InlineCode>
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">border-color</InlineCode>
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">outline-color</InlineCode> 
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">fill</InlineCode>
      </li>
    </ul>
    <p>{`Other properties like `}<InlineCode mdxType="InlineCode">{`box-shadow`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`text-shadow`}</InlineCode>{` are set
to `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{`. To give you an example, this is what my blog looks like in forced colors
mode with a dark theme:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABJ0AAASdAHeZh94AAACDElEQVQ4y32S227aQBCGx+tdXNnG5xqCDT5hoDgRASsoCFLU3JH3f58/2jUlaUi5+DWjsefbOVFREI5HwvMzIc8JsxlhMiHsdoS67nwZW60Itk0gIjDBoP/Qz5ZD8O6bphFIQl5eOsB6TVguCYtFF2vbD/90IhhGB9QNBmELBeWWgNEjBP4ZSCTwIQ4i/ZP4P2KMQwgOrnPoTFfi0nIBTRMQQoAklbGObtsWojBAHEeI4xC9HgfnDELoyuq6fiWNaarqc3VSFwe+78NxHBiGAdd10e/3wTlXifJ1xpiS9C9ATcNnBnX0Lvj29obfxyOetltst1u07Qa73Q6HwwGbzRr7wwFPbYtm2SjYF9A18M/rK/b7A06nEx4fV1c/f1PN98C/StIUVTVFWVbIJhmSJEFRlsiyCcbjFFmWIU3HKIsSeV4gCILbwDiOFVAmp2mqgHKOpmXBtEw4Th+WZcG2bTiOC8bEbaDnuRgMY4RhiDAI1GJkYv8McD1PPeB5ATxfXgSBaTeAReGi3QRomhr3zQPqusav5RIP9w3KokCSpEqjUYI8H6PIQzCm/R/ouh7uhiNE0U8MBgPcjUZqDFEUXs7INE3VvrRE7HbLslU59OFwgEmWqcVkWY4wHKAoSkynUxUryxJVVcH3vNvAUZJgNp+phPliriB5PsG0jhCGPjzPRxD4aruyYnnkX4HvZ71GguBQt/4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A sub page of \"Oida, is des org\" with forced colors mode active.",
          "title": "A sub page of \"Oida, is des org\" with forced colors mode active.",
          "src": "/static/7adfa1d753cf88b3c2caa77167718657/078fe/oida-is-des-org-blog-high-contrast.png",
          "srcSet": ["/static/7adfa1d753cf88b3c2caa77167718657/5a46d/oida-is-des-org-blog-high-contrast.png 300w", "/static/7adfa1d753cf88b3c2caa77167718657/0a47e/oida-is-des-org-blog-high-contrast.png 600w", "/static/7adfa1d753cf88b3c2caa77167718657/078fe/oida-is-des-org-blog-high-contrast.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`How to test forced colors mode`}</h2>
    <p>{`You can use the
`}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/devtools/rendering/emulate-css/#emulate-css-media-feature-forced-colors"
      }}>{`developer tools in Chrome and Edge`}</a>{`
to emulate forced colors mode and check the visibility of your content:`}</p>
    <ol>
      <li parentName="ol">{`Open the Rendering tab.`}</li>
      <li parentName="ol">{`Under "Emulate CSS media feature forced-colors", select "forced-colors: active" from the dropdown list.`}</li>
    </ol>
    <h2>{`The `}<InlineCode mdxType="InlineCode">{`forced-colors`}</InlineCode>{` media query`}</h2>
    <p>{`Use the media query `}<InlineCode mdxType="InlineCode">{`forced-colors`}</InlineCode>{` to apply certain styling only when forced colors mode
is active. For example, I've included a SVG pattern in the background of my blog using
the `}<InlineCode mdxType="InlineCode">{`background-image`}</InlineCode>{` property:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`#___gatsby {
    background-image: url("data:image/svg+xml...");
    background-attachment: fixed;
    background-size: cover;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Using the media query, I remove the background image when forced colors mode is active:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@media (forced-colors: active) {
    #___gatsby {
        background-image: none;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Another useful CSS property is `}<InlineCode mdxType="InlineCode">{`forced-color-adjust`}</InlineCode>{`. It allows you to opt certain elements
out of forced colors mode and optimize its styling yourself.`}</p>
    <h2>{`Transparent borders and outlines`}</h2>
    <p>{`Web layouts often communicate the boundary of an element with a different `}<InlineCode mdxType="InlineCode">{`background-color`}</InlineCode>{`
or via `}<InlineCode mdxType="InlineCode">{`box-shadow`}</InlineCode>{`. In forced colors mode, all elements have the same background color and shadows
are removed. This lack of clear boundaries between elements can be confusing for users.`}</p>
    <p>{`But don't despair! There's a clever solution for this problem: Set a border with the color `}<InlineCode mdxType="InlineCode">{`transparent`}</InlineCode>{`.
When forced colors mode replaces the color value, the border becomes visible and communicates the boundaries of
the element:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`section.info-card {
    border: 1px solid transparent;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can use the same logic to ensure visible focus indicators in forced colors mode. Usually, browsers add an outline to
a link or button when it receives focus. It's totally fine to remove the outline with `}<InlineCode mdxType="InlineCode">{`outline: none`}</InlineCode>{`
if you provide a different focus indicator, e.g., a different background color. As this won't work in forced colors mode,
the better solution is to make the outline transparent:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`a:focus {
    background: var(--primary);
    color: var(--white);
    /* Provide visible focus indicator in forced colors mode */
    outline: 2px solid transparent;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`There's even more great CSS techniques to fix common accessibility issues in forced colors mode. Please watch the
video `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=vlx70ABSAP0"
      }}>{`“Practical Styling in Forced Colors Mode”`}</a>{` by Mike Herchel.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/forced-colors"
        }}>{`forced-colors (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/forced-color-adjust"
        }}>{`forced-color-adjust (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2022/03/windows-high-contrast-colors-mode-css-custom-properties/"
        }}>{`Windows High Contrast Mode, Forced Colors Mode And CSS Custom Properties`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      